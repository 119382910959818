import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import moment from 'moment';
import { NavigationProvider } from '@/navigation/navigation.provider';
import { useGetToken } from '@/auth/hooks/authentication';
import InactivityMonitor from '@/components/inactivity-monitor';
import { ModelType } from '@/constants';
import { ANALYTICS_KEY, AnalyticsDashboard, DEFAULT_DASHBOARD_TYPE, getFolder, normalizeDashboardTitle, } from '@/search-for-retail/analytics';
import { getCollections } from '@/search-for-retail/collections/collections.client';
import { addError, storeAllCollections } from '@/store/actions/creators';
import { getAreaList } from '@/search-for-retail/area/area.client';
import { creators } from '@/store/actions/merchandising';
import { getEnvSubdomainFromHostname } from '@/client/utils';
import { MainSectionWrapper } from '@/components/content-section';
import { usePathname, useShouldDisplayAnalytics } from '@/routing/hooks/utils';
import { createDrawerConfig } from '@/navigation/drawer/drawer.config';
import { activeUserSelector, useActiveArea, useTenantIdSelector } from '@/store/selectors';
import { DrawerContainer } from '@/navigation/drawer';
import { logError } from '@/utils/logger';
import { useHandleAuthOnLocationChanged, useHandleModelTypeOnLocationChanged, useUpdateAreaOnLocationChanged } from '@/routing/hooks';
import { getModelPathFromPathname } from '@/routing';
import { useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { SimpleLoading } from '@/components/loading';
export const LoggedInLayout = () => {
    var _a;
    const dispatch = useDispatch();
    const [dashboards, setDashboards] = useState([]);
    const [currentDashboardType, setCurrentDashboardType] = useState(DEFAULT_DASHBOARD_TYPE);
    const [areDashboardsLoading, setAreDashboardsLoading] = useState(true);
    const { featureFlagsService } = useFeatureFlagsProvider();
    const drawerConfig = useMemo(() => createDrawerConfig(featureFlagsService), [featureFlagsService]);
    const pathname = usePathname();
    const getToken = useGetToken();
    const modelPath = getModelPathFromPathname(pathname);
    const shouldDisplayAnalytics = useShouldDisplayAnalytics(modelPath);
    const activeAreaName = useActiveArea();
    const [isAreaLoading, setIsAreaLoading] = useState(false);
    useHandleModelTypeOnLocationChanged();
    useHandleAuthOnLocationChanged();
    useUpdateAreaOnLocationChanged();
    const setAnalyticsDashboardType = useCallback((dashboardType) => {
        setCurrentDashboardType(dashboardType);
    }, []);
    const loadCollections = useCallback((token) => __awaiter(void 0, void 0, void 0, function* () {
        if (!token)
            return;
        try {
            const data = yield getCollections(token);
            dispatch(storeAllCollections(data));
        }
        catch (e) {
            const errorMessage = 'TASK_COLLECTIONS_LOAD_ALL_ERROR';
            logError(errorMessage, e);
            dispatch(addError({ key: errorMessage }));
        }
    }), [dispatch]);
    const loadAreas = useCallback((token) => __awaiter(void 0, void 0, void 0, function* () {
        if (!token)
            return;
        try {
            const data = yield getAreaList(token);
            dispatch(creators.storeAllModels(ModelType.AREA, data));
        }
        catch (e) {
            const errorMessage = 'TASK_AREA_LOAD_ALL_ERROR';
            logError(errorMessage, e);
            dispatch(addError({ key: errorMessage }));
        }
    }), [dispatch]);
    const user = useSelector(activeUserSelector);
    const tenantId = useTenantIdSelector();
    useEffect(() => {
        if (!window.pendo)
            return;
        window.pendo.initialize({
            visitor: {
                id: user.id,
                email: user.email,
                full_name: user.name,
            },
            account: {
                id: tenantId,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        function action() {
            return __awaiter(this, void 0, void 0, function* () {
                setIsAreaLoading(true);
                const token = yield getToken();
                yield loadCollections(token);
                yield loadAreas(token);
                setIsAreaLoading(false);
            });
        }
        void action();
    }, [dispatch, loadCollections, loadAreas, getToken]);
    // fetch analytics dashboards folders
    useEffect(() => {
        const action = () => __awaiter(void 0, void 0, void 0, function* () {
            const token = yield getToken();
            if (!token || isNil(activeAreaName) || isAreaLoading) {
                setAreDashboardsLoading(false);
                return;
            }
            setAreDashboardsLoading(true);
            let folder;
            const envSubdomain = getEnvSubdomainFromHostname(window.location.hostname);
            const analyticsCacheKey = `${ANALYTICS_KEY}.${envSubdomain}`;
            try {
                const folderCacheStr = localStorage.getItem(analyticsCacheKey);
                if (!isNil(folderCacheStr)) {
                    const folderCache = JSON.parse(folderCacheStr);
                    const isWithin7Days = moment(folderCache.dateRead).add(7, 'days').isAfter(new Date());
                    if (isWithin7Days) {
                        folder = folderCache.folder;
                    }
                }
                if (isNil(folder)) {
                    folder = yield getFolder(token);
                    localStorage.setItem(analyticsCacheKey, JSON.stringify({
                        dateRead: new Date(),
                        folder,
                    }));
                }
                // prevent WSOD when dashboards is set to undefined for certain tenants or bad account setups
                if (Array.isArray(folder.dashboards)) {
                    setDashboards(folder.dashboards);
                }
            }
            catch (e) {
                logError('failed to load dashboards', e);
            }
            finally {
                setAreDashboardsLoading(false);
            }
        });
        void action();
    }, [activeAreaName, isAreaLoading, getToken]);
    if (isAreaLoading || areDashboardsLoading) {
        return <SimpleLoading />;
    }
    const currentDashboard = (_a = dashboards.find((dashboard) => normalizeDashboardTitle(dashboard.title) === currentDashboardType)) !== null && _a !== void 0 ? _a : dashboards[0];
    return (<React.Fragment>
      <NavigationProvider dashboards={dashboards} drawerConfig={drawerConfig}>
        <DrawerContainer displayAnalytics={shouldDisplayAnalytics}>
          <MainSectionWrapper>
            <Outlet context={{ setAnalyticsDashboardType }}/>
          </MainSectionWrapper>
        </DrawerContainer>
        {(shouldDisplayAnalytics && currentDashboard && activeAreaName) && <AnalyticsDashboard dashboard={currentDashboard}/>}
      </NavigationProvider>
      <InactivityMonitor />
    </React.Fragment>);
};
